<script lang='ts'>
    interface Breadcrumb {
        name: string;
        url?: string;
    }

    import {page} from '$app/stores';
    import {PUBLIC_URL} from '$env/static/public';

    export let breadcrumbs: Breadcrumb[] = [];
    export let title = 'CondoGenius | Condo Reviews for Toronto and GTA';
    export let description = 'Find authentic reviews of condo buildings in Toronto, the Greater Toronto Area (GTA), and more.';
    export let image = '';
    const defaultImageURL = `${PUBLIC_URL}/meta/generic.jpg`;
    let url = $page.url;
    $: url = $page.url;

    $: {
        switch($page?.route?.id) {
            case "/(app)/buildings/[locality]/[sub_locality]-n[id=integer]/[slug]":
                image = image || defaultImageURL;
                break;
            case "/(app)/add-review":
                image = `${PUBLIC_URL}/meta/add_review.jpg`;
                break;
            case "/(app)/about":
                image = `${PUBLIC_URL}/meta/about.jpg`;
                break;
            case "/(app)/about/terms-of-service":
                image = `${PUBLIC_URL}/meta/tos.jpg`;
                break;
            case "/(app)/about/privacy-policy":
                image = `${PUBLIC_URL}/meta/privacy.jpg`;
                break;
            case "/(app)/buildings/search":
                image = `${PUBLIC_URL}/meta/find_building.jpg`;
                break;
            case "/(app)/buildings/[locality]/[sub_locality]-n[id=integer]":
                switch($page.params?.sub_locality) {
                    case "downtown":
                        image = `${PUBLIC_URL}/meta/downtown.jpg`;
                        break;
                    case "midtown":
                        image = `${PUBLIC_URL}/meta/midtown.jpg`;
                        break;
                    case "etobicoke":
                        image = `${PUBLIC_URL}/meta/etobicoke.jpg`;
                        break;
                    case "scarborough":
                        image = `${PUBLIC_URL}/meta/scarborough.jpg`;
                        break;
                    case "north-york":
                        image = `${PUBLIC_URL}/meta/north_york.jpg`;
                        break;
                    default:
                        image = defaultImageURL;
                        break;
                }
                break;
            case "/(app)/login":
                if ($page.url.searchParams.get('redirect') === '/add-review') {
                    image = `${PUBLIC_URL}/meta/add_review.jpg`;
                } else {
                    image = defaultImageURL;
                }
                break;
            default:
                image = defaultImageURL;
        }
    }

    let breadcrumbsSerialized = '';
    $: {
        breadcrumbsSerialized = serializeBreadcrumbs(breadcrumbs);
    }

    function serializeBreadcrumbs(bc: Breadcrumb[]) {
        if (bc.length > 0) {
            const schema = {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": bc.map((breadcrumb: Breadcrumb, i: number) => {
                    return {
                        "@type": "ListItem",
                        "position": i + 1,
                        "name": breadcrumb.name,
                        ...(breadcrumb.url ? {
                            "item": `https://www.condogenius.io${breadcrumb.url}`
                        } : {})
                    }
                })
            };
            return `
    <script type="application/ld+json">
      ${JSON.stringify(schema)}
    ${'<'}/script>
  `
        }
        else {
            return '';
        }
    }
</script>

<svelte:head>
    <title>{title}</title>
    <meta property='og:title' content={title}>
    <meta name='description' content={description} />
    <meta name='og:description' content={description} />
    <meta name='og:image' content={image} />
    <meta name='og:image:secure_url' content={image} />
    <meta property='og:url' content={url}>
    <link rel='canonical' href='{url}' />
    {#if breadcrumbsSerialized}
        {@html breadcrumbsSerialized}
    {/if}
</svelte:head>
